import './App.css';
import EnhancedTable from './components/table.tsx'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {
  BrowserRouter as Router
} from "react-router-dom"

function App() {

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });


  return (
    <div className="App">
      <Router>
      <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <EnhancedTable/>
      </ThemeProvider>
      </Router>
    </div>
  );
}

export default App;
