import React from 'react';
import './imageTooltip.css';

interface ImageTooltipProps {
  imageUrl: string;
}

interface ImageTooltipState {
  isHovered: boolean;
}

class ImageTooltip extends React.Component<ImageTooltipProps, ImageTooltipState> {
  constructor(props: ImageTooltipProps) {
    super(props);
    this.state = {
      isHovered: false,
    };
  }

  handleMouseEnter = () => {
    this.setState({ isHovered: true });
  };

  handleMouseLeave = () => {
    this.setState({ isHovered: false });
  };

  render() {
    const { imageUrl } = this.props;
    const { isHovered } = this.state;

    return (
      <div className="tooltip-container" onMouseEnter={() => { this.handleMouseEnter() }} onMouseLeave={() => { this.handleMouseLeave() }}>
        <img src={imageUrl} alt="box controller" width="100" height="50" className="tooltip" />
        {isHovered && (
          <div className="tooltip-content">
            <img src={imageUrl} alt="box controller" width="400" height="200" />
          </div>
        )}
      </div>
    );
  }
}

export default ImageTooltip;