import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { visuallyHidden } from '@mui/utils';
import ImageTooltip from './imageTooltip.tsx';

interface Data {
  id: number;
  name: string;
  link: string;
  price: number;
  photo: string;
  size: string;
  tags: Array<string>;
}

function createData(
  id: number,
  name: string,
  link: string,
  price: number,
  photo: string,
  size: string,
  tags: Array<string>
): Data {
  return {
    id,
    name,
    link,
    price,
    photo,
    size,
    tags,
  };
}

const rows = [
  createData(1, 'B0xx', 'https://b0xx.com/collections/all', 230, 'b0xx.webp', '16 x 8 x 1.36', ['sanwa switch']),
  createData(2, 'Frame1 heavy', 'https://frame1.gg/collections/all', 250, 'frame1heavy.webp', '14.2 x 6.5 x 0.88', ['optical switch', 'hotswap', 'case included']),
  createData(3, 'Frame1 light', 'https://frame1.gg/collections/all', 150, 'frame1light.webp', '14.2 x 6.5 x 0.88', ['mx-style switch', 'hotswap', 'case included']),
  createData(4, 'Lbx', 'https://junkfoodarcades.com/collections/lbx', 185, 'lbx.webp', '12.5 x 6 x 0.75', ['kailh choc switch', 'hotswap']),
  createData(5, 'Superslab', 'https://prong.studio/products/superslab', 280, 'superslab.png', '13.375 x 6.375 x .625', ['kailh choc switch', 'hotswap', 'case included', 'artwork layer', 'RGB LEDs']),
  createData(6, 'Smashbox', 'https://www.hitboxarcade.com/products/smash-box', 250, 'smashbox.webp', '16 x 8 x 2.25', ['sanwa switch', 'wasd compatible']),
  createData(7, 'Tadpole', 'https://ko-fi.com/ranalabs/shop', 130, 'Tadpole.jpg', '7.1 x 4.3 x 0.5', ['mouse switch', 'wasd compatible']),
  createData(8, 'Schism', 'https://wunvzn.com/products/the-schism', 350, 'schism.jpg', '? x ? x ?', ['mx-style switch', 'hotswap']),
  createData(9, 'Gram Slim Smash', 'https://gramctrl.com/products/gram-slim-smash', 200, 'gramslim.webp', '12.4 x 6.9 x ~.5', ['kailh choc switch', 'hotswap', 'wasd compatible']),
  createData(10, 'Glyph', 'https://www.satisfye.com/collections/glyph', 260, 'glyph.PNG', '? x ? x ?', ['mx-style switch', 'hotswap', 'wasd compatible', 'artwork layer', 'RGB LEDs']),
  createData(11, 'Bubblebox', 'https://www.etsy.com/shop/StarLightCTRL', 185, 'bubblebox.PNG', '? x ? x ?', ['mx-style switch', 'hotswap', 'wasd compatible']),
  createData(12, 'Fightbox', 'https://www.aliexpress.us/item/3256805966588763.html', 130, 'fightbox.webp', '14 x 7.17 x .7', ['mx-style switch', 'hotswap']),
  createData(13, 'Cross Cut', 'https://rectanglecorner.com/products/crosscut', 250, 'crosscut.webp', '14 x 6.5 x 1', ['mx-style switch', 'hotswap', 'hybrid-capable', 'led display', 'rumble']),

];

const listoftags = ['mx-style switch', 'kailh choc switch', 'optical switch', 'sanwa switch', 'mouse switch', 'hotswap', 'case included', 'wasd compatible', 'artwork layer', 'RGB LEDs', 'hybrid-capable', 'led display', 'rumble'];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string | Array<string> },
  b: { [key in Key]: number | string | Array<string> },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}


function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Product/Link',
  },
  {
    id: 'price',
    numeric: true,
    disablePadding: false,
    label: 'Price (USD)',
  },
  {
    id: 'photo',
    numeric: false,
    disablePadding: false,
    label: 'Photo',
  },
  {
    id: 'size',
    numeric: false,
    disablePadding: false,
    label: 'Dimensions (in.)',
  },
  {
    id: 'tags',
    numeric: false,
    disablePadding: false,
    label: 'Tags',
  },
];

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } =
    props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align='left'
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ paddingLeft: headCell.id === 'name' ? "10px" : "" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableToolbar() {

  return (
    <Toolbar>
      {(
        <>
          <Typography
            sx={{ flex: '1 1 100%' }}
            variant="h3"
            id="tableTitle"
            component="div"
          >
            Box Controllers List
          </Typography>
        </>
      )}
    </Toolbar>
  );
}

function AdditionalResources() {

  return (
    <>
      <br />
      <h1 style={{ marginBottom: '0px' }}>Additional Resources</h1>
      <h2 style={{ margin: '0px' }}>Smash Controller Swap Discord</h2>
      <div>In the controller swap discord server, you might be able to buy a box some user is trying to sell.</div>
      <a style={{ color: "white" }} href='https://discord.gg/rxtC44pZFp'>GCC Hardware Swap</a>
      <h2 style={{ margin: '10px 0px 0px 0px' }}>DIY</h2>
      <div>Cranes DIY discord server is full of amazing resources for you to build your own box controller.</div>
      <a style={{ color: "white" }} href='https://discord.gg/S3qgZWD'>Crane's Lab</a>
    </>
  );
}
export default function EnhancedTable() {
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('price');

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    const sorted = stableSort(filteredData, getComparator(isAsc ? 'desc' : 'asc', property))
    setFilteredData(sorted);

  };
  const initialSorted = stableSort(rows, getComparator('asc', 'price'))
  const [filteredData, setFilteredData] = useState(initialSorted);
  const [selectedButtons, setSelectedButtons] = useState<string[]>([]);

  const handleFormat = (
    event: React.MouseEvent<HTMLElement>,
    newSelectedButtons: string[],
  ) => {
    setSelectedButtons(newSelectedButtons);
    const filtered = rows.filter(item =>
      newSelectedButtons.every(filterItem =>
        item.tags.includes(filterItem)
      )
    );
    const sorted = stableSort(filtered, getComparator(order, orderBy))
    setFilteredData(sorted);

  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar />
        <div style={{ textAlign: 'center' }}>
          Listed below are links to some popular box products and some features for comparison. <br />
          Please do additional research yourself by clicking on the link to the product's website.<br />
          Products might be sold out or discontinued, and listed for archival purposes (comparison and potentially buying used elsewhere).
        </div>
        <>
          <br />
          <div>Filter by Tag:</div>
          <ToggleButtonGroup value={selectedButtons} onChange={handleFormat}>
            {listoftags.map((tag) => (
              <ToggleButton
                key={tag}
                value={tag}
                size='small'
                sx={{
                  textTransform: 'none',
                }}>
                {tag}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {filteredData.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      <a href={row.link.toString()} style={{ color: "white", paddingLeft: "10px" }}>{row.name}</a>
                    </TableCell>
                    <TableCell align="left">{row.price}</TableCell>
                    <TableCell align="left">
                      {/*</TableCell> </> <img src={require('../images/' + row.photo)} width="100" height="50" alt={row.name} /> */}
                      <ImageTooltip imageUrl={require('../images/' + row.photo)} />
                    </TableCell>
                    <TableCell align="left">{row.size}</TableCell>
                    <TableCell align="left">{row.tags.join(', ')}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <AdditionalResources />
      </Paper>
    </Box>
  );
}